import React, { useState } from "react";
import styles from "./NewProduct.module.sass";
import NameAndDescription from "./NameAndDescription";
import requestHandler from "../../actions/httpClient";
import { useLocation, useNavigate } from "react-router";
import { addNotification } from "../../components/Notification";

const UpdateCoin = ({ }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const updateMarketHandler = async (priceDecimalPrecision, amountDecimalPrecision, marketOrderValue, statusValue,
    marketPrice, currentMarketPrice, buyPrice, sellPrice, dayChange, weekChange, cap, volume, botAvgBalanceFrom, botAvgBalanceTo,
    marketPlaceId, maxLeverageTrading, marginLeverageSpread, marginTrading) => {
    setLoading(true);
    const data = {
      market_id: state.id,
      priceDecimalPrecision: parseInt(priceDecimalPrecision),
      amountDecimalPrecision: parseInt(amountDecimalPrecision),
      disableMarketOrder: marketOrderValue.toLowerCase() === "yes" ? parseInt(process.env.REACT_APP_MARKET_ORDER_YES) : parseInt(process.env.REACT_APP_MARKET_ORDER_NO),
      status: statusValue.toLowerCase() === "enable" ? parseInt(process.env.REACT_APP_MARKET_STATUS_ENABLE) : parseInt(process.env.REACT_APP_MARKET_STATUS_DISABLE),
      signature: localStorage.getItem("signature"),
      marketPrice: marketPrice,
      currentMarketPrice: currentMarketPrice,
      buyPrice: buyPrice,
      sellPrice: sellPrice,
      dayChange: dayChange,
      weekChange: weekChange,
      cap: cap,
      volume: volume,
      bot_avg_bal_from: botAvgBalanceFrom,
      bot_avg_bal_to: botAvgBalanceTo,
      marketplaceid: marketPlaceId,
      maxLeverageTrading: JSON.stringify(maxLeverageTrading),
      margin_leverage_spread: marginLeverageSpread,
      isMarginTrading: marginTrading?.toLowerCase() === "enable" ? 1 : 0,
    };
    try {
      const updateMarketPayload = await requestHandler("update_market", "post", data, "jwt_token");
      setLoading(false);
      if (updateMarketPayload && updateMarketPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateMarketPayload?.data?.message[0]?.msg,
          type: 'success'
        });
        navigate("/trade/market");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            className={styles.card}
            state={state}
            updateMarketHandler={updateMarketHandler}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default UpdateCoin;
