import React from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { addNotification } from "../../../components/Notification";

const Form = ({
  className,
  title,
  setTitle,
  message,
  setMessage,
  userTypeValue,
  setUserTypeValue,
  usersTypeValues,
  notificationTypeValue,
  setNotificationTypeValue,
  notificationTypeValues,
  navigationTypeValue,
  setNavigationTypeValue,
  navigationTypeValues,
  regionValues,
  regionValue,
  customerKycStatusValues,
  customersValue,
  customerHandler,
  filteredOptions,
  userType,
  userId,
  setUserId,
  handleRadioChange,
  addNotificationHandler,
  fromDate,
  handleFromDateChange,
}) => {

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      <div className={styles.customField}>
        <Dropdown
          label="Select Notification Type"
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={notificationTypeValue}
          setValue={setNotificationTypeValue}
          options={notificationTypeValues}
          small
        />
      </div>
      <div className={styles.customField}>
        <Dropdown
          label="Select Navigation Type"
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={navigationTypeValue}
          setValue={setNavigationTypeValue}
          options={navigationTypeValues}
          small
        />
      </div>
      <div className={styles.customField}>
        <Dropdown
          label="Select User Type"
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={userTypeValue}
          setValue={setUserTypeValue}
          options={usersTypeValues}
          small
        />
      </div>
      {userTypeValue?.toLowerCase() !== "all" &&
        <div className={styles.userselectionform}>
          <div className={styles.radiobuttonscontainer}>
            <label>
              <input
                type="radio"
                name="userType"
                value="individual"
                checked={userType === 'individual'}
                onChange={handleRadioChange}
              />
              Individual User
            </label>
            <label>
              <input
                type="radio"
                name="userType"
                value="setOfUsers"
                checked={userType === 'setOfUsers'}
                onChange={handleRadioChange}
              />
              Multiple Users
            </label>
          </div>
          <div className={styles.selectedoptioncontainer}>
            {userType === "individual" ? (
              <div className={styles.customField}>
                <TextInput
                  className={styles.field}
                  label="UserId"
                  name="userid"
                  type="text"
                  value={userId}
                  onChange={(e) => {
                    setUserId([e.target.value]);
                  }}
                  placeholder="Please enter userid"
                />
              </div>
            ) : (
              <div className={styles.customField}>
                <label>Select Customers</label>
                <Select
                  options={customerKycStatusValues}
                  isMulti
                  onChange={customerHandler}
                />
              </div>
            )}
          </div>
        </div>
      }
      <div className={styles.customField}>
        <label>Select Region</label>
        <Select
          options={regionValues}
          isMulti
          onChange={filteredOptions}
        />
      </div>
      <div className={styles.customField}>
        <TextInput
          className={styles.field}
          label="Title"
          name="title"
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          placeholder="Please enter a value"
          required
        />
      </div>
      <div className={styles.customField}>
        <TextInput
          className={styles.field}
          label="Message"
          name="message"
          type="text"
          value={message}
          maxLength={500}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Please enter a value"
          required
        />
      </div>
      <div className={styles.datePickerContainer}>
        <label className={styles.datePickerLabel}>Time and Date(Optional)</label>
        <ReactDatePicker
          selected={fromDate}
          onChange={(date) => handleFromDateChange(date)}
          className={styles.customDatePicker}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="yyyy-MM-dd HH:mm"
          minDate={new Date()}
          placeholderText="Select Schedule time"
        />
      </div>
      <div className={styles.customField}>
        <button className={cn("button-small")}
          onClick={(e) => {
            e.preventDefault();
            if (notificationTypeValue !== "Select a value") {
              if (regionValue !== null && regionValue !== undefined && regionValue?.length != 0) {
                if (userTypeValue?.toLowerCase() === "customer" || userTypeValue?.toLowerCase() === "corporate") {
                  if (userType === "individual") {
                    if (userId?.length !== 0) {
                      if (title !== "" && message !== "") {
                        addNotificationHandler();
                      }
                      else {
                        addNotification({
                          title: "Alert",
                          message: "Title and message should not be empty",
                          type: "danger",
                        });
                      }
                    }
                    else {
                      addNotification({
                        title: "Alert",
                        message: "Please enter user id",
                        type: "danger",
                      });
                    }
                  }
                  else if (userType === "setOfUsers") {
                    if (customersValue !== null && customersValue !== undefined && customersValue?.length != 0) {
                      if (title !== "" && message !== "") {
                        addNotificationHandler();
                      }
                      else {
                        addNotification({
                          title: "Alert",
                          message: "Title and message should not be empty",
                          type: "danger",
                        });
                      }
                    }
                    else {
                      addNotification({
                        title: "Alert",
                        message: "Please select customers",
                        type: "danger",
                      });
                    }
                  }
                  else {
                    addNotification({
                      title: "Alert",
                      message: "Please select customers",
                      type: "danger",
                    });
                  }
                }
                else if (title !== "" && message !== "") {
                  addNotificationHandler();
                }
                else {
                  addNotification({
                    title: "Alert",
                    message: "Title and message should not be empty",
                    type: "danger"
                  });
                }
              }
              else {
                addNotification({
                  title: "Alert",
                  message: "Please Select Region",
                  type: "danger",
                });
              }
            }
            else {
              addNotification({
                title: "Alert",
                message: "Please Select Notification Type",
                type: "danger",
              });
            }
          }}>
          Submit
        </button>
      </div>
    </Card >
  );
};

export default Form;
