import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import CustomDropdown from "../../../../components/CustomDropdown";
import TextInput from "../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";

const Share = ({
  regionData,
  regionText,
  regionHandler,
  setRegionText,
  marketListData,
  marketText,
  setMarketText,
  marketHandler,
  low,
  setLow,
  high,
  setHigh,
  percentageLow,
  setPercentageLow,
  percentageHigh,
  setPercentageHigh,
  steps,
  setSteps,
  addNotificationRuleHandler
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();

  return (
    <>
      <div className={styles.share}>
        <div className={styles.head}>
          <div className={cn("title-red", styles.title)}>Add Notification Rule</div>
        </div>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Region"
            options={regionData}
            text={regionText}
            setText={setRegionText}
            handleClick={regionHandler}
          />
        </div>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Markets"
            options={marketListData}
            text={marketText}
            setText={setMarketText}
            handleClick={marketHandler}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter Low Amount"
            name="lowAmount"
            value={low}
            onChange={(e) => {
              setLow(e.target.value)
            }}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("lowAmount")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("lowAmount", low, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter High Amount"
            name="highAmount"
            value={high}
            onChange={(e) => {
              setHigh(e.target.value)
            }}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("highAmount")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("highAmount", high, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter Low Percentage"
            name="lowPercentage"
            value={percentageLow}
            onChange={(e) => {
              setPercentageLow(e.target.value)
            }}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("lowPercentage")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("lowPercentage", percentageLow, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter High Percentage"
            name="highPercentage"
            value={percentageHigh}
            onChange={(e) => setPercentageHigh(e.target.value)}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("highPercentage")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("highPercentage", percentageHigh, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Steps"
            name="steps"
            value={steps}
            onChange={(e) => setSteps(e.target.value)}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("steps")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("steps", steps, "required")}</span>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              addNotificationRuleHandler(low, high, percentageLow, percentageHigh, steps);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }}
          className={cn("button-small", styles.button)}
        >
          Save Changes
        </button>
      </div>
    </>
  );
};

export default Share;
