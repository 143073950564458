import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import LoaderScreen from "../../../components/LoaderScreen";
import CustomDropdown from "../../../components/CustomDropdown";
import { addNotification } from "../../../components/Notification";
import requestHandler from "../../../actions/httpClient";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

let coinsNetwork = [];
const Form = ({ className }) => {
  const statusOptions = ["Enable", "Disable"];
  const stableCoinOptions = [{ id: 1, name: 'Yes' }, { id: 2, name: 'No' }];
  const enableDepositOptions = [{ id: 1, name: 'Enable' }, { id: 0, name: 'Disable' }];
  const enableWithdrawOptions = [{ id: 1, name: 'Enable' }, { id: 0, name: 'Disable' }];
  const maintenanceModeOption = ["Yes", "No"];
  const [coinName, setCoinName] = useState("");
  const [coinSymbol, setCoinSymbol] = useState("");
  const [coinMaxFee, setCoinMaxFee] = useState();
  const [coinMinFee, setCoinMinFee] = useState();
  const [coinMinBalance, setCoinMinBalance] = useState();
  const [stableCoin, setStableCoin] = useState(stableCoinOptions[0].name);
  const [icon, setIcon] = useState();
  const [status, setStatus] = useState(statusOptions[0]);
  const [maintenanceMode, setMaintenanceMode] = useState(maintenanceModeOption[1]);
  const animatedComponents = makeAnimated();
  const [networkSlug, setNetworkSlug] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [decimalPrecision, setDecimalPrecision] = useState();
  const [fireblockId, setFireblockId] = useState("");
  const [enableDepositText, setEnableDepositText] = useState(enableDepositOptions[0].name);
  const [enableDepositValue, setEnableDepositValue] = useState(enableDepositOptions[0].id);
  const [enableWithdrawText, setEnableWithdrawText] = useState(enableWithdrawOptions[0].name);
  const [enableWithdrawValue, setEnableWithdrawValue] = useState(enableWithdrawOptions[0].id);
  const [visibleModal, setVisibleModal] = useState(false);
  const [spreadValue, setSpreadValue] = useState("");
  const [convertSpreadValue, setConvertSpreadValue] = useState("");
  const [priceDecimalPrecision, setPriceDecimalPrecision] = useState("");

  let data = {
    signature: localStorage.getItem("signature"),
  };

  useEffect(() => {
    coinsNetwork = []
    coinsNetworkData();
  }, []);

  const coinsNetworkData = async () => {
    setLoading(true);
    try {
      const coinsNetworkPayload = await requestHandler("get_coin_network", "post", data, "jwt_token");
      setLoading(false);
      coinsNetworkPayload?.data?.data?.map((x) => coinsNetwork.push({ "value": x?.id, "label": `${x?.network_slug} (${x?.network_name})` }));
    }
    catch (e) {
      setLoading(false);
    }
  }

  const addNewCoinHandler = async (coinName, coinSymbol, coinMaxFee, coinMinFee,
    coinMinBalance, icon, maintenanceMode, status, stableCoin, networkSlug, decimalPrecision, fireblockId, spreadValue, convertSpreadValue, priceDecimanlPrecision) => {
    setLoading(true);
    let data = {
      name: coinName,
      symbol: coinSymbol,
      maxFee: coinMaxFee,
      minFee: coinMinFee,
      minBalance: coinMinBalance,
      icon: icon,
      status: status.toLowerCase() === "enable" ? parseInt(process.env.REACT_APP_COIN_STATUS_ENABLE) : parseInt(process.env.REACT_APP_COIN_STATUS_DISABLE),
      maintenanceMode: maintenanceMode.toLowerCase() === "yes" ? parseInt(process.env.REACT_APP_COIN_MAINTENENCE_MODE_YES) : parseInt(process.env.REACT_APP_COIN_MAINTENENCE_MODE_NO),
      stableCoin: stableCoin.toLowerCase() === "yes" ? parseInt(process.env.REACT_APP_STABLE_COIN_YES) : parseInt(process.env.REACT_APP_STABLE_COIN_NO),
      signature: localStorage.getItem("signature"),
      networks: networkSlug ? JSON.stringify(networkSlug) : null,
      decimalPrecision: decimalPrecision,
      fireblock_id: fireblockId,
      enable_deposit: enableDepositValue,
      enable_withdraw: enableWithdrawValue,
      internalbot: process.env.REACT_APP_COIN_INTERNALBOT_VALUE,
      spread_value: parseInt(process.env.REACT_APP_COIN_SPREAD_VALUE),
      margin_spread_value: spreadValue,
      convert_spread_value: convertSpreadValue,
      priceDecimanlPrecision: priceDecimanlPrecision,
    };
    try {
      const addNewCoinPayload = await requestHandler("new_coin", "post", data, "jwt_token", "file");
      setLoading(false);
      if (addNewCoinPayload && addNewCoinPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: addNewCoinPayload?.data?.message[0]?.msg,
          type: 'success'
        });
        navigate("/trade/coins");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    }
  };

  const getCoinsNetwork = async (val) => {
    if (val.id == 1) {
      setStableCoin(val.name);
    }
    else {
      setStableCoin(val.name);
    }
  };

  const handleChange = (x) => {
    if (x.length === 0) {
      setNetworkSlug([]);
    }
    x.map(y => setNetworkSlug([...networkSlug, y.value]))
  };

  const enableDepositHandler = (x) => {
    setEnableDepositText(x.name);
    setEnableDepositValue(x.id);
  };

  const enableWithdrawHandler = (x) => {
    setEnableWithdrawText(x.name);
    setEnableWithdrawValue(x.id);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Fireblock Id"
            name="fireblockId"
            type="text"
            placeholder="Please enter a value"
            value={fireblockId}
            onChange={(e) => { setFireblockId(e.target.value) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Price Decimal Precision"
            name="priceDecimalPrecision"
            type="text"
            placeholder="Please enter a value"
            value={priceDecimalPrecision}
            onChange={(e) => {
              const regex = /^\d*$/;
              if (regex.test(e.target.value) || e.target.value === '') {
                setPriceDecimalPrecision(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Coin Name"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={coinName}
            onChange={(e) => { setCoinName(e.target.value) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Coin Symbol"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={coinSymbol}
            onChange={(e) => { setCoinSymbol(e.target.value) }}
            required
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Max Fee"
            name="title"
            placeholder="Please enter a value"
            type="text"
            value={coinMaxFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCoinMaxFee(e.target.value)
              }
              //  setCoinMaxFee(e.target.value)
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Min Fee"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={coinMinFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCoinMinFee(e.target.value)
              }
              // setCoinMinFee(e.target.value) 
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Min Balance"
            name="minBalance"
            type="text"
            placeholder="Please enter a value"
            value={coinMinBalance}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCoinMinBalance(e.target.value)
              }
              // setCoinMinBalance(e.target.value)
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Amount Decimal Precision"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={decimalPrecision}
            onChange={(e) => {
              const re = /^\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setDecimalPrecision(e.target.value)
              }
              // setDecimalPrecision(e.target.value) 
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Margin Spread Value"
            name="marginspreadValue"
            type="text"
            placeholder="Please enter a value"
            value={spreadValue}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setSpreadValue(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Convert Spread Value"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={convertSpreadValue}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setConvertSpreadValue(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Icon"
            name="icon"
            type="file"
            placeholder="Please enter a value"
            onChange={(e) => { setIcon(e.target.files[0]) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Maintenance Mode"
            value={maintenanceMode}
            setValue={setMaintenanceMode}
            options={maintenanceModeOption}
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Enable Deposit"
            options={enableDepositOptions}
            text={enableDepositText}
            setText={setEnableDepositText}
            handleClick={enableDepositHandler}
          />
        </div>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Enable Withdraw"
            options={enableWithdrawOptions}
            text={enableWithdrawText}
            setText={setEnableWithdrawText}
            handleClick={enableWithdrawHandler}
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Status"
            value={status}
            setValue={setStatus}
            options={statusOptions}
          />
        </div>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Stable Coin"
            options={stableCoinOptions}
            text={stableCoin}
            setText={setStableCoin}
            handleClick={getCoinsNetwork}
          />
        </div>
      </div>
      <br />
      {
        // stableCoin == "No" &&
        <>
          <p>Please Select Network</p>
          <Select
            placeholder="Please select a value"
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={coinsNetwork}
            defaultValue={selectedOption}
            onChange={handleChange}
          />
        </>
      }
      <br />
      <button
        onClick={(e) => {
          e.preventDefault()
          if (spreadValue !== "" && convertSpreadValue !== "" && fireblockId !== "" && coinName !== "" && coinSymbol !== "" && coinMaxFee >= 0 &&
            coinMinFee >= 0 && coinMinBalance >= 0 && icon != undefined && decimalPrecision >= 0 && priceDecimalPrecision >= 0) {
            addNewCoinHandler(coinName, coinSymbol, coinMaxFee, coinMinFee,
              coinMinBalance, icon, maintenanceMode, status, stableCoin, networkSlug, decimalPrecision, fireblockId, spreadValue, convertSpreadValue, priceDecimalPrecision);
          }
          else {
            addNotification({
              title: 'Error',
              message: "Please fill all the fields and make sure the entered data is valid.",
              type: 'danger'
            });
          }
        }
        }
        className={cn("button", styles.button)}
      >
        Save Changes
      </button>
    </Card >
  );
};

export default Form;
