import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import TextInput from "../../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import Dropdown from "../../../../../components/Dropdown";

const statusOption = ["Enable", "Disable"];

const Share = ({
  notificationDetails,
  updateNotificationRuleHandler
}) => {
  const [low, setLow] = useState(notificationDetails?.m_coin_notification_rules?.[0]?.rule?.[0]?.low);
  const [high, setHigh] = useState(notificationDetails?.m_coin_notification_rules?.[0]?.rule?.[0]?.high);
  const [percentageLow, setPercentageLow] = useState(notificationDetails?.m_coin_notification_rules?.[0]?.rule?.[0]?.p_low);
  const [percentageHigh, setPercentageHigh] = useState(notificationDetails?.m_coin_notification_rules?.[0]?.rule?.[0]?.p_high);
  const [steps, setSteps] = useState(notificationDetails?.m_coin_notification_rules?.[0]?.rule?.[0]?.steps);
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [status, setStatus] = useState(notificationDetails?.m_coin_notification_rules?.[0]?.status === 1 ? statusOption[0] : statusOption[1]);

  return (
    <>
      <div className={styles.share}>
        <div className={styles.head}>
          <div className={cn("title-red", styles.title)}>Update Rule</div>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter Low Amount"
            name="lowAmount"
            value={low}
            onChange={(e) => {
              setLow(e.target.value)
            }}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("lowAmount")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("lowAmount", low, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter High Amount"
            name="highAmount"
            value={high}
            onChange={(e) => {
              setHigh(e.target.value)
            }}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("highAmount")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("highAmount", high, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter Low Percentage"
            name="lowPercentage"
            value={percentageLow}
            onChange={(e) => {
              setPercentageLow(e.target.value)
            }}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("lowPercentage")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("lowPercentage", percentageLow, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Enter High Percentage"
            name="highPercentage"
            value={percentageHigh}
            onChange={(e) => setPercentageHigh(e.target.value)}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("highPercentage")}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("highPercentage", percentageHigh, "required")}</span>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Steps"
            name="steps"
            value={steps}
            onChange={(e) => setSteps(e.target.value)}
            type="text"
            placeholder="Please enter amount"
            required
            onBlur={() => simpleValidator.current.showMessageFor("steps")}
          />
        </div>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Status"
            value={status}
            setValue={setStatus}
            options={statusOption}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("steps", steps, "required")}</span>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              updateNotificationRuleHandler(low, high, percentageLow, percentageHigh, steps, status, notificationDetails?.m_coin_notification_rules?.[0]?.marketId);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }}
          className={cn("button-small", styles.button)}
        >
          Save Changes
        </button>
      </div>
    </>
  );
};

export default Share;
