import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({
  item,
  index,
  setVisibleModal,
  setNotificationDetails
}) => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {index + 1}
        </div>
        <div className={styles.col}>
          {item?.slug?.toUpperCase()}
        </div>
        <div className={styles.col}>
          {item?.m_coin_notification_rules?.[0]?.rule?.[0]?.low}
        </div>
        <div className={styles.col}>
          {item?.m_coin_notification_rules?.[0]?.rule?.[0]?.high}
        </div>
        <div className={styles.col}>
          {item?.m_coin_notification_rules?.[0]?.rule?.[0]?.p_low}
        </div>
        <div className={styles.col}>
          {item?.m_coin_notification_rules?.[0]?.rule?.[0]?.p_high}
        </div>
        <div className={styles.col}>
          {item?.m_coin_notification_rules?.[0]?.rule?.[0]?.steps}
        </div>
        <div className={styles.col}>
          {item?.m_coin_notification_rules?.[0]?.status === parseInt(process.env.REACT_APP_ENABLE_NOTIFICATION_RULE_STATUS) ?
            <div className={cn("status-green-dark", styles.distribution)}>
              Enable
            </div>
            :
            <div className={cn("status-red-dark", styles.distribution)}>
              Disable
            </div>
          }
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {toLocaleStringDateFormat(item?.m_coin_notification_rules?.[0]?.createdAt)}
          </div>
        </div>
        <div className={styles.col}>
          <button className={cn("button-small")} onClick={() => {
            setVisibleModal(true);
            setNotificationDetails(item);
          }}>
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;
