import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [coinsData, setCoinsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const currencies = process.env.REACT_APP_CURRENCIES?.split(",");

  useEffect(() => {
    getCoins();
  }, []);

  let data = {
    signature: localStorage.getItem("signature"),
  };

  const getCoins = async () => {
    setLoading(true);
    try {
      const coinsPayload = await requestHandler("getCoins", "post", data, "jwt_token");
      setLoading(false);
      setCoinsData(coinsPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const createMarketHandler = async (market, coin) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      currency: market,
      coinId: coin,
      region: market?.toLowerCase() === "eur"
        ? parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE)
        : market?.toLowerCase() === "usdt"
          ? parseInt(process.env.REACT_APP_INTERNATIONAL_REGION_VALUE)
          : parseInt(process.env.REACT_APP_SURINAME_REGION_VALUE)
    };
    try {
      const createMarketPayload = await requestHandler("auto_create_new_market", "post", data, "jwt_token");
      if (createMarketPayload.status === 200) {
        addNotification({
          title: "Success",
          message: createMarketPayload?.data?.message?.[0]?.msg,
          type: "success",
        });
        setLoading(false);
        await getCoins();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message?.[0]?.msg,
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          coinsData={coinsData}
          loading={loading}
          currencies={currencies}
          createMarketHandler={createMarketHandler}
        />
      </div>
    </>
  );
};

export default Promote;
