import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import LoaderScreen from "../../../components/LoaderScreen";
import SimpleReactValidator from "simple-react-validator";

const marketOption = ["Yes", "No"];
const statusOption = ["Enable", "Disable"];
const marginTradingOptions = ["Enable", "Disable"];

const NameAndDescription = ({
  className,
  state,
  updateMarketHandler,
  loading,
}) => {
  const [priceDecimalPrecision, setPriceDecimalPrecision] = useState(state?.priceDecimalPrecision);
  const [amountDecimalPrecision, setAmountDecimalPrecision] = useState(state?.amountDecimalPrecision);
  const [marketOrderValue, setMarketOrderValue] = useState(marketOption[0]);
  const [statusValue, setStatusValue] = useState(state.status === 0 ? "Disable" : "Enable");
  const [marketPrice, setMarketPrice] = useState(state.marketPrice);
  const [currentMarketPrice, setCurrentMarketPrice] = useState(state.currentMarketPrice);
  const [buyPrice, setBuyPrice] = useState(state.buyPrice);
  const [sellPrice, setSellPrice] = useState(state.sellPrice);
  const [dayChange, setDayChange] = useState(state.dayChange);
  const [weekChange, setWeekChange] = useState(state.weekChange);
  const [cap, setCap] = useState(state.cap);
  const [volume, setVolume] = useState(state.volume);
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState("");
  const [botAvgBalanceFrom, setBotAvgBalanceFrom] = useState(state.bot_avg_bal_from);
  const [botAvgBalanceTo, setBotAvgBalanceTo] = useState(state.bot_avg_bal_to);
  const [marketPlaceId, setMarketPlaceId] = useState(state?.marketplaceid);
  const [marginTrading, setMarginTrading] = useState(
    state?.isMarginTrading === 0 ? "Disable" : "Enable"
  );
  const [maxLeverageTrading, setMaxLeverageTrading] = useState(state?.maxLeverageTrading ? state?.maxLeverageTrading : "");
  const [marginLeverageSpread, setMarginLeverageSpread] = useState(state?.margin_leverage_spread ? state?.margin_leverage_spread : "");

  useEffect(() => {
    if (state?.isMarginTrading === 0) {
      setMarginTrading(marginTradingOptions[1]);
    } else if (state?.isMarginTrading === 1) {
      setMarginTrading(marginTradingOptions[0]);
    }
  }, [state?.isMarginTrading]);

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Market Slug"
            name="title"
            type="text"
            defaultValue={state?.slug}
            required
            disabled={true}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Name"
            name="title"
            type="text"
            defaultValue={state?.name}
            required
            disabled={true}
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Market Price"
            name="marketPrice"
            placeholder="Please enter a value"
            value={marketPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMarketPrice(e.target.value)
              }
            }}
            onBlur={() => simpleValidator.current.showMessageFor("marketPrice")}
            required
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("marketPrice", marketPrice, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Current Market Price"
            name="CurrentMarketPrice"
            type="text"
            placeholder="Please enter a value"
            value={currentMarketPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCurrentMarketPrice(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("CurrentMarketPrice")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("CurrentMarketPrice", currentMarketPrice, "required")}
          </span>
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Buy Price"
            name="buyPrice"
            type="text"
            placeholder="Please enter a value"
            value={buyPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBuyPrice(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("buyPrice")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("buyPrice", buyPrice, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Sell Price"
            name="sellPrice"
            type="text"
            placeholder="Please enter a value"
            value={sellPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setSellPrice(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("sellPrice")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("sellPrice", sellPrice, "required")}
          </span>
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Day Change"
            name="dayChange"
            type="text"
            placeholder="Please enter a value"
            value={dayChange}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setDayChange(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("dayChange")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("dayChange", dayChange, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Week Change"
            name="weekChange"
            type="text"
            placeholder="Please enter a value"
            value={weekChange}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setWeekChange(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("weekChange")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("weekChange", weekChange, "required")}
          </span>
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Cap"
            name="cap"
            type="text"
            placeholder="Please enter a value"
            value={cap}
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCap(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("cap")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("cap", cap, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Volume"
            name="volume"
            type="text"
            placeholder="Please enter a value"
            value={volume}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setVolume(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("volume")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("volume", volume, "required")}
          </span>
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Price Decimal Precision"
            name="decimalPrecision"
            type="text"
            placeholder="Please enter a value"
            value={priceDecimalPrecision}
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setPriceDecimalPrecision(e.target.value);
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("decimalPrecision")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("decimalPrecision", priceDecimalPrecision, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Amount Decimal Precision"
            name="amountDecimalPrecision"
            type="text"
            placeholder="Please enter a value"
            value={amountDecimalPrecision}
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setAmountDecimalPrecision(e.target.value);
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("amountDecimalPrecision")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("amountDecimalPrecision", amountDecimalPrecision, "required")}
          </span>
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Disable Market Order"
            value={marketOrderValue}
            setValue={setMarketOrderValue}
            options={marketOption}
          />
        </div>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Status"
            value={statusValue}
            setValue={setStatusValue}
            options={statusOption}
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Market Place Id"
            name="marketPlaceId"
            type="text"
            placeholder="Please enter a value"
            value={marketPlaceId}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMarketPlaceId(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("marketPlaceId")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("marketPlaceId", marketPlaceId, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Margin Trading"
            value={marginTrading}
            setValue={setMarginTrading}
            options={marginTradingOptions}
          />
        </div>
      </div>
      <div className={styles.description}>
        {
          marginTrading?.toLowerCase() === "enable" &&
          <>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Margin Leverage Spread"
                name="marginLeverageSpread"
                type="text"
                placeholder="Please enter a value"
                value={marginLeverageSpread}
                onChange={(e) => {
                  const re = /^\d+(\.\d+)?$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setMarginLeverageSpread(e.target.value)
                  }
                }}
                required
                onBlur={() => simpleValidator.current.showMessageFor("marginLeverageSpread")}
              />
              <span className={styles.danger}>
                {simpleValidator.current.message("marginLeverageSpread", marginLeverageSpread, "required")}
              </span>
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Max Leverage Trading"
                name="maxLeverageTrading"
                type="text"
                placeholder="Please enter a value"
                value={maxLeverageTrading}
                onChange={(e) => {
                  setMaxLeverageTrading(e.target.value)
                }}
                required
                onBlur={() => simpleValidator.current.showMessageFor("maxLeverageTrading")}
              />
              <span className={styles.danger}>
                {simpleValidator.current.message("maxLeverageTrading", maxLeverageTrading, "required")}
              </span>
            </div>
          </>
        }
      </div>
      <hr />
      <h4 className={styles.description}>Bot Settings Starts from here</h4>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Balance(From)"
            name="botAvgBalanceFrom"
            type="text"
            placeholder="Please enter a value"
            value={botAvgBalanceFrom}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgBalanceFrom(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("botAvgBalanceFrom")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("botAvgBalanceFrom", botAvgBalanceFrom, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Balance(To)"
            name="botAvgBalanceTo"
            type="text"
            placeholder="Please enter a value"
            value={botAvgBalanceTo}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgBalanceTo(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("botAvgBalanceTo")}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("botAvgBalanceTo", botAvgBalanceTo, "required")}
          </span>
        </div>
      </div>
      <br />
      <button
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (marginTrading?.toLowerCase() === "enable") {
              updateMarketHandler(priceDecimalPrecision, amountDecimalPrecision, marketOrderValue,
                statusValue, marketPrice, currentMarketPrice, buyPrice, sellPrice, dayChange, weekChange, cap, volume,
                botAvgBalanceFrom, botAvgBalanceTo, marketPlaceId, maxLeverageTrading, marginLeverageSpread, marginTrading);
            }
            else {
              updateMarketHandler(priceDecimalPrecision, amountDecimalPrecision, marketOrderValue,
                statusValue, marketPrice, currentMarketPrice, buyPrice, sellPrice, dayChange, weekChange, cap, volume,
                botAvgBalanceFrom, botAvgBalanceTo, marketPlaceId, null, null,
                marginTrading);
            }
          }
          else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }
        }}
        className={cn("button", styles.button)}
      >
        Save Changes
      </button>
    </Card >
  );
};

export default NameAndDescription;
