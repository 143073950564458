import React, { useEffect, useRef } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { useState } from "react";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [marketListData, setMarketListData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [marketText, setMarketText] = useState();
  const [marketValue, setMarketValue] = useState();
  const [marketCurrency, setMarketCurrency] = useState('');
  const [loading, setLoading] = useState(false);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...marketListData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setMarketListData(copyListItems);
  };

  useEffect(() => {
    getRegion();
  }, []);

  useEffect(() => {
    if (marketValue !== undefined) {
      getMarkets(marketValue);
    }
  }, [marketValue, pageRefreshFlag]);

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == 'eur') {
          getMarkets(regionPayload?.data?.data[i]?.id);
          setMarketText(regionPayload?.data?.data[i]?.name);
          setMarketValue(regionPayload?.data?.data[i]?.id);
        }
      }
      const newDropDownValue = regionPayload?.data?.data?.map((x) => { return { "id": x.id, "name": x.name } });
      setLoading(false);
      setRegionData([...newDropDownValue]);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const reOrderMarketListHandler = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        market_positions: marketListData,
      };
      const reOrderMarketListPayload = await requestHandler("update_market_position", "post", data, "jwt_token");
      setLoading(false);
      if (reOrderMarketListPayload && reOrderMarketListPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: reOrderMarketListPayload?.data?.message[0]?.msg,
          type: 'success'
        });
      };
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getMarkets = async (id) => {
    setLoading(true);
    try {
      let data = {
        type: "adminMarkets",
        region: id,
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      setLoading(false);
      let newArr = [];
      for (let i = 0; i < marketsPayload?.data?.data?.length; i++) {
        marketsPayload?.data?.data?.[i]?.markets?.map((x) => { newArr.push(x) })
      }
      newArr.sort(compare);
      setMarketListData([...newArr]);
      setMarketCurrency(marketsPayload?.data?.data[0].currency);


      // let newArr = marketsPayload?.data?.data?.[0]?.markets?.map((x) => { return x });
      // newArr.sort(compare);
      // setMarketListData([...newArr]);
      // setMarketCurrency(marketsPayload?.data?.data[0].currency);
    }
    catch (e) {
      setLoading(false);
    };
  };

  function compare(a, b) {
    if (a.market_position < b.market_position) {
      return -1;
    }
    if (a.market_position > b.market_position) {
      return 1;
    }
    return 0;
  };

  const updateHistoryHandler = async (marketId) => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        region: marketValue,
        marketId: marketId,
      };
      const updateHistoryPayload = await requestHandler("update_market_manually", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(true);
      addNotification({
        title: 'Success',
        message: updateHistoryPayload?.data?.message[0]?.msg,
        type: 'success'
      });
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(true);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  return (
    <div className={styles.section}>
      <RecentPost
        marketCurrency={marketCurrency}
        marketListData={marketListData}
        marketText={marketText}
        setMarketText={setMarketText}
        marketValue={marketValue}
        setMarketValue={setMarketValue}
        regionData={regionData}
        loading={loading}
        dragStart={dragStart}
        dragEnter={dragEnter}
        drop={drop}
        reOrderMarketListHandler={reOrderMarketListHandler}
        updateHistoryHandler={updateHistoryHandler}
      />
    </div>
  );
};

export default Promote;
