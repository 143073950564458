import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import TextArea from "../../../components/TextArea";
import OrderDropdown from "../../../components/OrderDropdown";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

const riskProfileData = [
  { id: parseInt(process.env.REACT_APP_RISK_PROFILE_LOW), name: 'Low' },
  { id: parseInt(process.env.REACT_APP_RISK_PROFILE_MEDIUM), name: 'Medium' },
  { id: parseInt(process.env.REACT_APP_RISK_PROFILE_HIGH), name: 'High' }
];

const authData = [
  { id: 1, name: 'Y' },
  { id: 2, name: 'N' },
];

const ProfileInformation = ({ className,
  profileInfo,
  setProfileInfo,
  updateUserHandler,
  regionData,
  superadmin,
  complianceOfficer,
  updateCountryHandler,
  analystUser
}) => {
  const [regionText, setRegionText] = useState();
  const [regionValue, setRegionValue] = useState();
  const [riskProfileText, setRiskProfileText] = useState("");
  const [riskProfileValue, setRiskProfileValue] = useState("");
  const [authText, setAuthText] = useState("");
  const [authValue, setAuthValue] = useState("");
  const inputRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [fields, setFields] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState();

  useEffect(() => {
    regionData?.filter(x => {
      if (x?.id === profileInfo?.region) {
        setRegionText(x?.name);
        setRegionValue(x?.id);
        setSelectedRegion(x?.slug);
      }
    });
    riskProfileData.filter((x) => {
      if (x.id === profileInfo.risk_profile) {
        setRiskProfileText(x.name);
      }
    })
    authData.filter((x) => {
      if (x.name === profileInfo.auth_enable) {
        setAuthText(x.name);
      }
    })

    if (profileInfo && profileInfo?.mobile_no) {
      setPhoneNumber(`${profileInfo?.country_code}${profileInfo?.mobile_no}`)
      setCountryCode(profileInfo?.country_code)
    }

  }, [profileInfo]);

  return (
    <Item
      className={cn(styles.card, className)}
      title={`Profile information (${profileInfo?.region === parseInt(process.env.REACT_APP_INTERNATIONAL_REGION_VALUE) ? "International" :
        profileInfo?.region === parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE) ? "Europe" : ""})`}
      classTitle="title-green"
    >
      <div className={styles.fieldset}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Customer Id"
            name="customerId"
            value={profileInfo?.id === undefined || profileInfo?.id === null ? "" : profileInfo?.id}
            type="customerId"
            required
            disabled={true}
          />
          <TextInput
            className={styles.field}
            label="User Id"
            name="userId"
            value={profileInfo?.uid === undefined || profileInfo?.uid === null ? "" : profileInfo?.uid}
            type="userId"
            required
            disabled={true}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="First Name"
            name="fname"
            value={profileInfo?.fname === null || profileInfo?.fname === undefined ? "" : profileInfo?.fname}
            type="text"
            onChange={(e) => setProfileInfo(profileInfo => ({ ...profileInfo, fname: e.target.value }))}
            required
            disabled={(!superadmin && !complianceOfficer && !analystUser)}
          />
          <TextInput
            className={styles.field}
            label="Last Name"
            name="lname"
            value={profileInfo?.lname === null || profileInfo?.lname === undefined ? "" : profileInfo?.lname}
            type="text"
            required
            onChange={(e) => setProfileInfo(profileInfo => ({ ...profileInfo, lname: e.target.value }))}
            disabled={(!superadmin && !complianceOfficer && !analystUser)}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Email"
            name="email"
            value={profileInfo?.email === undefined || profileInfo?.email === null ? "" : profileInfo?.email}
            type="email"
            required
            disabled={true}
          />
          <TextInput
            className={styles.field}
            label="Mobile Number"
            name="mobileNumber"
            value={profileInfo?.mobile_no === undefined || profileInfo?.mobile_no === null ? "" : `(${profileInfo?.country_code === null || profileInfo?.country_code === undefined ? "---" : profileInfo?.country_code})${profileInfo?.mobile_no}`}
            type="text"
            required
            disabled={true}
          />
          {fields ?
            <div className={styles.box}>
              <p>Mobile Number</p>
              <PhoneInput
                className={styles.field}
                ref={inputRef}
                country={'us'}
                value={phoneNumber}
                name="phone"
                countryCode
                onChange={(phone, country) => {
                  setPhoneNumber(phone)
                  setCountryCode(country.dialCode);
                }}
              />
            </div>
            : ""}
          {(superadmin || complianceOfficer || analystUser) &&
            <button
              className={cn("button-small button-success", styles.button)}
              onClick={(e) => {
                e.preventDefault();
                setFields(!fields);
                if (e.target.innerHTML === "Save Changes") {
                }
              }}
            >
              {fields ? "Close" : "Edit"}
              {/* Edit */}
            </button>
          }
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bank Account Count"
            name="bankAccountCount"
            value={profileInfo?.bank_account_count === undefined || profileInfo?.bank_account_count === null ? "" : profileInfo?.bank_account_count}
            type="text"
            required
            disabled={true}
          />
          <TextInput
            className={styles.field}
            label="Fireblock Id"
            name="fireblockId"
            value={profileInfo?.fireBlock_vault_id === null || profileInfo?.fireBlock_vault_id === undefined ? "" : profileInfo?.fireBlock_vault_id}
            type="text"
            required
            disabled={true}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="User Level"
            name="userLevel"
            value={profileInfo?.user_level === undefined || profileInfo?.user_level === null ? "" : profileInfo?.user_level}
            type="text"
            required
            disabled={true}
          />
          <TextInput
            className={styles.field}
            label="Bank Verification Status"
            name="bankVerification"
            value={profileInfo?.bank_verification === undefined || profileInfo?.bank_verification === null ? "" : profileInfo?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONINPROCESS) ? "In-process" :
              profileInfo?.bank_verification === undefined || profileInfo?.bank_verification === null ? "" : profileInfo?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) ? "Pending" :
                profileInfo?.bank_verification === undefined || profileInfo?.bank_verification === null ? "" : profileInfo?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) ? "Completed" :
                  profileInfo?.bank_verification === undefined || profileInfo?.bank_verification === null ? "" : profileInfo?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONFAILURE) ? "Failed" :
                    profileInfo?.bank_verification === undefined || profileInfo?.bank_verification === null ? "" : profileInfo?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) ? "Rejected" :
                      null
            }
            type="text"
            required
            disabled={true}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Monitoring Code"
            name="monitoringCode"
            value={profileInfo?.monitor_kyc_id === undefined || profileInfo?.monitor_kyc_id === null ? "" : profileInfo?.monitor_kyc_id}
            type="text"
            required
            disabled={true}
          />

          <TextInput
            className={styles.field}
            label="Role"
            name="role"
            value={profileInfo?.role === undefined || profileInfo?.role === null ? "" : profileInfo?.role}
            type="text"
            required
            disabled={true}
          />
        </div>
        <div className={styles.customField}>
          <TextArea
            className={styles.field}
            label="Notes(Optional)"
            placeholder="Please enter a value"
            name="notes"
            onChange={(e) => setProfileInfo(profileInfo => ({ ...profileInfo, notes: e.target.value }))}
            type="text"
            value={profileInfo?.notes !== "" ? profileInfo?.notes : ""}
          />
        </div>
        {selectedRegion === process.env.REACT_APP_INTERNATIONAL_REGION &&
          <div className={styles.dropdownClass}>
            <OrderDropdown
              label="Select Region"
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={regionValue}
              setValue={setRegionValue}
              text={regionText}
              setText={setRegionText}
              options={regionData}
            />
          </div>
        }
        <div className={styles.dropdownClass}>
          <OrderDropdown
            label="Risk Profile"
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={riskProfileValue}
            setValue={setRiskProfileValue}
            text={riskProfileText}
            setText={setRiskProfileText}
            options={riskProfileData}
          />
        </div>
        {profileInfo.auth_enable?.toLowerCase() === "y" &&
          <div className={styles.dropdownClass}>
            <OrderDropdown
              label="Two FA"
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={authValue}
              setValue={setAuthValue}
              text={authText}
              setText={setAuthText}
              options={authData}
            />
          </div>
        }
        <div className={styles.random}>
          <div className={styles.customClass}>
            <TextInput
              className={styles.field}
              label="Email Verified Status"
              name="emailVerified"
              value={profileInfo?.email_verified === undefined || profileInfo?.email_verified === null ? "" : profileInfo?.email_verified === parseInt(process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS) ? "Unverified" :
                profileInfo?.email_verified === undefined || profileInfo?.email_verified === null ? "" : profileInfo?.email_verified === parseInt(process.env.REACT_APP_VERIFIED_EMAIL_CUSTOMERS) ? "Verified" :
                  profileInfo?.email_verified === undefined || profileInfo?.email_verified === null ? "" : profileInfo?.email_verified === parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) ? "Freezed" : null
              }
              type="text"
              required
              disabled={true}
            />
          </div>
          {(superadmin || complianceOfficer || analystUser) &&
            <div className={styles.row}>
              {profileInfo?.email_verified === parseInt(process.env.REACT_APP_VERIFIED_EMAIL_CUSTOMERS)
                ||
                profileInfo?.email_verified === parseInt(process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS) ?
                <div className={styles.buttonClass}>
                  <div className={styles.settings}>
                    <button className={cn("button-small",)} style={{ background: 'red' }} type="button"
                      onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, email_verified: parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) }))}
                    >
                      <span>Freeze User</span>
                    </button>
                  </div>
                </div>
                :
                profileInfo?.email_verified === parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) ?
                  <div className={styles.buttonClass}>
                    <div className={styles.settings}>
                      <button className={cn("button-small",)} style={{ background: 'green' }} type="button"
                        onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, email_verified: parseInt(process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS) }))}
                      >
                        <span>Unfreeze User</span>
                      </button>
                    </div>
                  </div>
                  :
                  ""
              }
            </div>}
        </div>
        <div className={styles.random}>
          <div className={styles.customClass}>
            <TextInput
              className={styles.field}
              label="KYC Verification Status"
              name="kycVerification"
              value={profileInfo?.kyc_verification === undefined || profileInfo?.kyc_verification === null ? "" : profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) ? "Pending" :
                profileInfo?.kyc_verification === undefined || profileInfo?.kyc_verification === null ? "" : profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONUSEREXIT) ? "User Exit" :
                  profileInfo?.kyc_verification === undefined || profileInfo?.kyc_verification === null ? "" : profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS) ? "In-process" :
                    profileInfo?.kyc_verification === undefined || profileInfo?.kyc_verification === null ? "" : profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONONERROR) ? "Token Expired" :
                      profileInfo?.kyc_verification === undefined || profileInfo?.kyc_verification === null ? "" : profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) ? "Completed" :
                        profileInfo?.kyc_verification === undefined || profileInfo?.kyc_verification === null ? "" : profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINREVIEW) ? "In-Review" :
                          null
              }
              type="text"
              required
              disabled={true}
            />
          </div>
          {(superadmin || complianceOfficer || analystUser) &&
            profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINREVIEW) ?
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <button className={cn("button",)} style={{ background: 'green' }} type="button"
                    onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, kyc_verification: parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) }))}
                  >
                    <span>Accept</span>
                  </button>
                </div>
              </div>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <button className={cn("button",)} style={{ background: 'red' }} type="button"
                    onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, kyc_verification: parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) }))}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </div>
            </div> : ""
          }
        </div>
        <div className={styles.random}>
          <div className={styles.customClass}>
            <TextInput
              className={styles.field}
              label="Residence Verification Status"
              name="residenceVerification"
              value={profileInfo?.residence_verification === undefined || profileInfo?.residence_verification === null ? "" : profileInfo?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_PENDING) ? "Pending" :
                profileInfo?.residence_verification === undefined || profileInfo?.residence_verification === null ? "" : profileInfo?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) ? "In-process" :
                  profileInfo?.residence_verification === undefined || profileInfo?.residence_verification === null ? "" : profileInfo?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ? "Completed" :
                    profileInfo?.residence_verification === undefined || profileInfo?.residence_verification === null ? "" : profileInfo?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ? "Rejected" : null
              }
              type="text"
              required
              disabled={true}
            />
          </div>
          {/* <div className={styles.row}>
            {profileInfo?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ?
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <button className={cn("button",)} style={{ background: 'red' }} type="button"
                    onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, residence_verification: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) }))}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </div>
              :
              profileInfo?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ?
                <div className={styles.buttonClass}>
                  <div className={styles.settings}>
                    <button className={cn("button",)} style={{ background: 'green' }} type="button"
                      onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, residence_verification: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) }))}
                    >
                      <span>Accept</span>
                    </button>
                  </div>
                </div>
                :
                <>
                  <div className={styles.buttonClass}>
                    <div className={styles.settings}>
                      <button className={cn("button",)} style={{ background: 'green' }} type="button"
                        onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, residence_verification: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) }))}
                      >
                        <span>Accept</span>
                      </button>
                    </div>
                  </div>
                  <div className={styles.buttonClass}>
                    <div className={styles.settings}>
                      <button className={cn("button",)} style={{ background: 'red' }} type="button"
                        onClick={() => setProfileInfo(profileInfo => ({ ...profileInfo, residence_verification: parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) }))}
                      >
                        <span>Reject</span>
                      </button>
                    </div>
                  </div>
                </>
            }
          </div> */}
        </div>
        {
          profileInfo?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) &&
          <div className={styles.random}>
            <div className={styles.customClass}>
              <TextInput
                className={styles.field}
                label="Country"
                name="country"
                value={profileInfo?.residence_address === undefined || profileInfo?.residence_address === null ? "" : profileInfo?.residence_address}
                type="text"
                required
                // disabled={true}
                onChange={(e) => setProfileInfo(profileInfo => ({ ...profileInfo, residence_address: e.target.value }))}
              />
            </div>
            {
              profileInfo?.residence_address === undefined || profileInfo?.residence_address === null && (
                <div className={styles.row}>
                  <div className={styles.buttonClass}>
                    <div className={styles.settings}>
                      <button className={cn("button-small")} type="button"
                        onClick={() => { updateCountryHandler() }}
                      >
                        <span>Update</span>
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        }
        {(superadmin || complianceOfficer || analystUser) && <button
          onClick={(e) => {
            e.preventDefault();
            updateUserHandler(profileInfo, regionValue ? regionValue : profileInfo?.region, riskProfileValue ? riskProfileValue : profileInfo?.risk_profile, authText, profileInfo?.monitor_kyc_id, phoneNumber ? phoneNumber : profileInfo?.mobile_no, countryCode ? countryCode : profileInfo?.country_code);
            setFields(false);
          }}
          className={cn("button-small", styles.button)}
        >
          Save Changes
        </button>}

      </div>
    </Item >
  );
};

export default ProfileInformation;
