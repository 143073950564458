import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  setVisibleModal,
  notificationRuleData,
  loading,
  setNotificationDetails,
  setAddRuleVisibleModal
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.control}>
              <div className={cn("button", styles.button)} onClick={() => {
                setAddRuleVisibleModal(true);
              }}>
                <span>Add New</span>
              </div>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Slug</div>
            <div className={styles.col}>Low</div>
            <div className={styles.col}>High</div>
            <div className={styles.col}>Percentage Low</div>
            <div className={styles.col}>Percentage High</div>
            <div className={styles.col}>Steps</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Update</div>
          </div>
          {
            loading ? <CustomerListSkeleton colCount={5} rowCount={12} />
              :
              notificationRuleData?.length > 0 ?
                <>
                  {
                    notificationRuleData?.map((x, index) => (
                      <Row
                        item={x}
                        key={index}
                        index={index}
                        setVisibleModal={setVisibleModal}
                        setNotificationDetails={setNotificationDetails}
                      />
                    ))}
                </>
                :
                "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
