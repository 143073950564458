import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const statusOptions = [
  { "id": parseInt(process.env.REACT_APP_LINKED_ACCOUNT_INPROCESS), "name": "In-process" },
  { "id": parseInt(process.env.REACT_APP_LINKED_ACCOUNT_ACTIVE), "name": "Approved" },
  { "id": parseInt(process.env.REACT_APP_LINKED_ACCOUNT_DECLINE), "name": "Declined" },
];

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [linkedAccountData, setLinkedAccountData] = useState([]);
  const [statusText, setStatusText] = useState(statusOptions[0].name);
  const [statusValue, setStatusValue] = useState(statusOptions[0].id);
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    getLinkedAccountListHandler();
  }, [statusValue]);

  const getLinkedAccountListHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: statusValue,
    };
    try {
      const getLinkedAccountListPayload = await requestHandler("getLinkedAccountDetails", "post", data, "jwt_token");
      setLinkedAccountData(getLinkedAccountListPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateLinkedAccountHandler = async (id, status) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      id: id,
      status: status,
    };
    try {
      const updateLinkedAccountPayload = await requestHandler("updateLinkedAccountDetail", "post", data, "jwt_token");
      setLoading(false);
      if (updateLinkedAccountPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: updateLinkedAccountPayload?.data?.message[0]?.msg,
          type: "success",
        });
        await getLinkedAccountListHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  const statusHandler = (x) => {
    setStatusValue(x.id);
    setStatusText(x.name);
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          linkedAccountData={linkedAccountData}
          updateLinkedAccountHandler={updateLinkedAccountHandler}
          statusText={statusText}
          setStatusText={setStatusText}
          statusValue={statusValue}
          setStatusValue={setStatusValue}
          statusOptions={statusOptions}
          statusHandler={statusHandler}
          searchField={searchField}
          setSearchField={setSearchField}
        />
      </div>
    </>
  );
};

export default Promote;
