import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import Modal from "../../components/Modal";
import AddRule from "./RecentPost/AddRule";
import UpdateRule from "./RecentPost/Row/Details";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationRuleData, setnotificationRuleData] = useState([]);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [marketListData, setMarketListData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [marketText, setMarketText] = useState();
  const [marketValue, setMarketValue] = useState();
  const [addRuleVisibleModal, setAddRuleVisibleModal] = useState(false);
  const [regionText, setRegionText] = useState("Select a value");
  const [regionValue, setRegionValue] = useState("");
  const [low, setLow] = useState("");
  const [high, setHigh] = useState("");
  const [percentageLow, setPercentageLow] = useState("");
  const [percentageHigh, setPercentageHigh] = useState("");
  const [steps, setSteps] = useState("");

  useEffect(() => {
    getRegion();
    getAllNotificationRuleHandler();
  }, []);

  const getAllNotificationRuleHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getAllNotificationRulePayload = await requestHandler("notificationRules", "post", data, "jwt_token");
      setLoading(false);
      setnotificationRuleData(getAllNotificationRulePayload?.data?.markets);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      const newDropDownValue = regionPayload?.data?.data?.map((x) => { return { "id": x.id, "name": x.name } });
      setLoading(false);
      setRegionData(newDropDownValue);

      if (newDropDownValue.length > 0) {
        getMarkets(newDropDownValue[0].id);
        setRegionText(newDropDownValue[0].name);
        setRegionValue(newDropDownValue[0].id);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getMarkets = async (id) => {
    setLoading(true);
    try {
      let data = {
        type: "adminMarkets",
        region: id,
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      setLoading(false);
      let newArr = [];
      for (let i = 0; i < marketsPayload?.data?.data?.length; i++) {
        marketsPayload?.data?.data?.[i]?.markets?.map((x) => { newArr.push(x) })
      }
      setMarketListData(newArr);
      setMarketText(newArr[0]?.name);
      setMarketValue(newArr[0]?.id);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const regionHandler = (x) => {
    setRegionText(x.name);
    setRegionValue(x.id);
    getMarkets(x.id);
  };

  const marketHandler = (x) => {
    setMarketText(x.name);
    setMarketValue(x.id);
  };

  const addNotificationRuleHandler = async (low, high, pLow, pHigh, steps) => {
    setLoading(true);
    let arrayData = {
      low: low,
      high: high,
      p_low: pLow,
      steps: steps,
      p_high: pHigh,
    };
    let data = {
      marketId: marketValue,
      rule: arrayData,
      signature: localStorage.getItem("signature"),
    };
    try {
      const addNotificationRulePayload = await requestHandler("addNotificationRule", "post", JSON.stringify(data), "jwt_token");
      setLoading(false);
      if (addNotificationRulePayload?.status === 200) {
        addNotification({
          title: "Success",
          message: addNotificationRulePayload?.data?.message?.[0]?.msg,
          type: "success",
        });
        await getAllNotificationRuleHandler();
        setAddRuleVisibleModal(false);
        setLow("");
        setHigh("");
        setPercentageLow("");
        setPercentageHigh("");
        setSteps("");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  const updateNotificationRuleHandler = async (low, high, pLow, pHigh, steps, status, id) => {
    setLoading(true);
    let arrayData = {
      low: low,
      high: high,
      p_low: pLow,
      steps: steps,
      p_high: pHigh,
    };
    let data = {
      marketId: id,
      rule: arrayData,
      signature: localStorage.getItem("signature"),
      status: status?.toLowerCase() === "enable" ? parseInt(process.env.REACT_APP_ENABLE_NOTIFICATION_RULE_STATUS) : parseInt(process.env.REACT_APP_DISABLE_NOTIFICATION_RULE_STATUS)
    };
    try {
      const updateNotificationRulePayload = await requestHandler("updateNotificationRule", "post", data, "jwt_token");
      setLoading(false);
      if (updateNotificationRulePayload?.status === 200) {
        addNotification({
          title: "Success",
          message: updateNotificationRulePayload?.data?.message?.[0]?.msg,
          type: "success",
        });
        await getAllNotificationRuleHandler();
        setVisibleModal(false);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    }
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <LoaderScreen />}
        <RecentPost
          setVisibleModal={setVisibleModal}
          notificationRuleData={notificationRuleData}
          setNotificationDetails={setNotificationDetails}
          setAddRuleVisibleModal={setAddRuleVisibleModal}
        />
      </div>

      <Modal
        visible={addRuleVisibleModal}
        onClose={() => { setAddRuleVisibleModal(false); }}
      >
        <AddRule
          regionData={regionData}
          regionText={regionText}
          setRegionText={setRegionText}
          regionHandler={regionHandler}
          marketListData={marketListData}
          marketText={marketText}
          marketValue={marketValue}
          setMarketText={setMarketText}
          marketHandler={marketHandler}
          low={low}
          setLow={setLow}
          high={high}
          setHigh={setHigh}
          percentageLow={percentageLow}
          setPercentageLow={setPercentageLow}
          percentageHigh={percentageHigh}
          setPercentageHigh={setPercentageHigh}
          steps={steps}
          setSteps={setSteps}
          addNotificationRuleHandler={addNotificationRuleHandler}
        />
      </Modal>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <UpdateRule
          notificationDetails={notificationDetails}
          updateNotificationRuleHandler={updateNotificationRuleHandler}
        />
      </Modal>
    </>
  );
};

export default Promote;
