import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import Icon from "../../../../../components/Icon";
import { toLocaleStringDateFormat } from "../../../../../components/helper";

const Share = ({ item }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Coin</div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img className={styles.img} srcSet={`${item.image2x} 2x`} src={item.icon} alt="Coin" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}
              ></div>
              <div className={styles.text}>
                {item.symbol}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.text}>
          Customer Id
        </div>
        <div className={styles.col}>
          {item?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Name
        </div>
        <div className={styles.col}>
          {(item?.fname === null || item?.fname === undefined || item?.fname === "") ? "---" : `${item?.fname} ${item?.lname}`}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Address</div>
        <div className={styles.col}>
          {item?.address}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Tag/Memo</div>
        <div className={styles.col}>
          {item?.address_tag === undefined || item?.address_tag === null || item?.address_tag === "" ? "---" : item?.address_tag}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Region</div>
        <div className={styles.col}>
          {item?.region}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Network Token</div>
        <div className={styles.col}>
          {(item?.network_slug === undefined || item?.network_slug === null || item?.network_slug === "") ? "---" : item?.network_slug}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>AML Report</div>
        <div className={styles.col}>
          {item?.amlReport ? item?.amlReport : "---"}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>AML Score</div>
        <div className={styles.col}>
          {item?.amlScore ? item?.amlScore : "---"}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>AMLBot Verified</div>
        <div className={styles.col}>
          {item?.isAmlbotverfied}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Ownership</div>
        <div className={styles.col}>
          {item?.ownership === null || item?.ownership === undefined || item?.ownership === "undefined" ? "--- " : item?.ownership}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Scan Ownership</div>
        <div className={styles.col}>
          {item?.type === parseInt(process.env.REACT_APP_INTERNAL_CRYPTO_ADDRESS) ?
            "NA"
            :
            <div className={styles.item}>
              <div className={styles.preview} onClick={(e) => { window.open(process.env.REACT_APP_IMAGE_URL + "/" + item.scanOwnership, '_blank') }}>
                <img className={styles.image} srcSet={`${item.image2x} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item.scanOwnership} alt="proof" />
              </div>
            </div>
          }
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) && (<div className={cn("status-red-dark", styles.distribution)}>Pending</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PROCESSING) && (<div className={cn("status-red-dark", styles.distribution)}>Processing</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) && (<div className={cn("status-green-dark", styles.distribution)}>Verified</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED) && (<div className={cn("status-red-dark", styles.distribution)}>Declined</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_CANCELLED) && (<div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default Share;
